function swipers() {


    if ( !! $j('#footer-news .swiper-container').length ) {

        var mySwiper = new Swiper ('#footer-news .swiper-container', {
            loop: true,
            lazy: true,
            navigation: {
                nextEl: '#footer-news .swiper-button-next',
                prevEl: '#footer-news .swiper-button-prev',
            },
            pagination: {
                el: '#footer-news .swiper-pagination',
                type: 'progressbar',
                dynamicBullets: true,
            },
            keyboard: {
                enabled: true,
            },
            grabCursor: true,
            autoplay: false,
            autoplay: {
                delay: 7500
            },
            slidesPerView: 3,
            spaceBetween: 25,
            breakpoints: {
                600: {
                    slidesPerView: 1,
                },
                979: {
                    slidesPerView: 2,
                },
                1400: {
                    slidesPerView: 3,
                }
            }
        });

    }

    if ( !! $j('#swiper-sidebar .swiper-container2').length ) {

        var mySwiper = new Swiper ('#swiper-sidebar .swiper-container', {
                //effect: 'fade', // coverflow cube
                //loop: true,
                //lazy: true,
                navigation: {
                    nextEl: '#swiper-sidebar .swiper-button-next',
                    prevEl: '#swiper-sidebar .swiper-button-prev',
                },
                pagination: {
                    el: '#swiper-sidebar .swiper-pagination',
                    type: 'progressbar',
                    dynamicBullets: true,
                },
                keyboard: {
                    enabled: true,
                },
                //watchSlidesVisibility: true,
                //watchSlidesProgress: true,
                //mousewheel: true,
                //autoHeight: true,
                grabCursor: true,
                //parallax: true,
                //freeMode: true,
                autoplay: false,
                slidesPerView: 1,
                spaceBetween: 10,
                autoplay: {
                    delay: 7500
                },
                // Responsive breakpoints
                breakpoints: {
                    // when window width is <= 320px
                    500: {
                        slidesPerView: 1,
                    },
                    // when window width is <= 480px
                    770: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                }
            });
    }

}
