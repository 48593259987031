function consult() {

    $j('.md-close, .md-bg').click(function(){
        $j('.modal').fadeOut(300);
        setTimeout(function(){
            $j('.modal .inner').fadeOut(300);
        },500);

    });

    $j('.consultbtn-popup').click(function(e){
        e.preventDefault();

        $j('#startconsult .loader').fadeIn(300);
        setTimeout(function(){
            $j('#startconsult').fadeIn(300);
        },300);
        setTimeout(function(){
            $j('#startconsult .loader').fadeOut(300);
        },900);

        setTimeout(function(){
            $j('#startconsult .inner').fadeIn(500);
        },1050);
    });


    $j(document).bind("keyup", function (event) {
        if ( event.keyCode == 27 && !! $j('.modal').find('.md-close').length ) {
            $j('.modal').find('.md-close').trigger('click');
        }
    });

}
