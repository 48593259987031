function mobile() {

    //https://css-tricks.com/forums/topic/prevent-body-scrolling-when-the-user-scrolls-on-fixed-position-div/
    /*
    $j(function($) {

        var fixed = document.getElementById('nav'), overflow;

        $(window).on('load resize', function() {

            overflow = fixed.scrollHeight-$('#nav').height();
        });

        $('#nav').on('touchmove', function() {

            if (overflow) return true;
            else return false;
        });
    });
    */


    if ( ! $j('#mnav-overlay').length )  {

        $j('body').append('<div id="mnav-bg"></div>');
        $j('body').append('<div id="mnav-overlay"></div>');

        //$j('#nav ul#menu-main').append('<li class="desk-hide">'+$j('.pls').html()+'</li>');

        // TOGGLE MOBILE NAV
        $j('#oc').click(function() {

            if ( ! $j('body.oc-open').length ) {
                $j('#nav, #mnav-overlay, #mnav-bg').fadeIn(200);
                $j('body').addClass('oc-open');
            } else {
                $j('#nav, #mnav-overlay, #mnav-bg').fadeOut(200);
                $j('body').removeClass('oc-open');

            }

        });

        // Overlay Click Triggers Toggle
        $j('#mnav-overlay').click(function() {
            $j('#oc').click();
        });

        //$j('#oc').click();

        /*http://labs.rampinteractive.co.uk/touchSwipe/demos/index.html
        $j('#mnav-bg').swipe({
            swipeStatus:function(event, phase, direction, distance, duration, fingers)
            {
                if (phase=="move" && direction =="right") {
                    $(".container").addClass("open-sidebar");
                    return false;
                }
                if (phase=="move" && direction =="left") {
                    $(".container").removeClass("open-sidebar");
                    return false;
                }
            }
        });*/

    }

}
