function loader() {
    if ( !! $j('.main-content').length ) {
        $j('.main-content').fadeTo(250,1);
    }
    if ( !! $j('.hero>div.loader:not(.home)').length ) {
        setTimeout(function(){
            $j('.hero>div.loader').fadeOut(loader_fadeout);
        },loader_delay);
    }
    if ( !! $j('.hero>div.loader.home').length ) {
        setTimeout(function(){
            $j('.hero>div.loader').fadeOut(loader_fadeout);
        },1900);

        setTimeout(function(){
            $j('.hero>div.loader.home .animation').fadeIn(300);
        },1000);
    }
    if ( !! $j('.contact-banner>div.loader').length ) {
        setTimeout(function(){
            $j('.contact-banner>div.loader').fadeOut(loader_fadeout);
        },loader_delay);
    }
    if ( !! $j('div.top-loader').length ) {
        setTimeout(function(){
            $j('div.top-loader').fadeOut(300);
        },1900);
    }
    $j('#fnav').find('li.current-menu-item').parents('.navgroup').addClass('active');
}



