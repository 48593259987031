function nav() {

    var fadetime = 250;

    $j(document).on('click', '#nav li:not(.consultbtn) a', function(event){

        event.preventDefault();

        var el = $j(this);
        var goLink = $j(this).attr('href');

        // Toggle sub menus when mobile nav open
        if(  el.parent().hasClass('menu-item-has-children') &&  $j('body.oc-open').length  ) {

            if ( el.parent().hasClass('open') ) {
                // Close Current Open Clicked
                el.find('span.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                el.next('ul').stop(true,false).slideUp(500, 'easeInOutExpo');
            } else {
                // Open Clicked SM
                el.find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
                el.next('ul').stop(true,false).slideDown(500, 'easeInOutExpo');
                // Close any open SM
                $j('li.open a').next('ul').stop(true,false).slideUp(500, 'easeInOutExpo');
                $j('li.open').find('span.fa-minus').addClass('fa-plus').removeClass('fa-minus');
                $j('li.open').removeClass('open');
            }

            el.parent().toggleClass('open');

        } else { // Regular Nav Click

            // Manage Active Classes
            $j('#nav a.active').removeClass('active');
            $j('#nav li.current-menu-item').removeClass('current-menu-item');
            el.addClass('active');
            el.parent().addClass('current-menu-item');
            $j('div.loader').fadeIn(150);
            $j('.intrologo').fadeIn(150);
            $j('.intrologo').addClass('outro');
            fadetime = 250;
            if( !! $j('body.oc-open').length ) {
                $j('#mnav-overlay').fadeOut(100);
                $j('body.oc-open').removeClass('oc-open');
                fadetime = 360;
            }
            setTimeout(function() { document.location.href=(goLink); }, fadetime);
        }
    });

    // Handle Subnav Items Mobile
    $j(document).on('click', '#nav2 li.menu-item-has-children a', function(e){
        if ( ! $j('body.oc-open').length ) {
            return;
        }
        e.preventDefault();
        var el = $j(this);
    });

   // Set Active Menu for Dropdown
   $j('li.menu-item-has-children ul>li>a').append('<span class="fas fa-angle-right ddarrow mobile-hide"></span>');
   $j('li.current_page_parent>a').find('span.fa-plus').addClass('fa-minus').removeClass('fa-plus');
   $j('li.current_page_parent').addClass('open');
   $j('li.current_page_parent a').next('ul').slideDown(10);


   $j('.scrolltop').click(function(){
       scrollToTop();
       return false;
   });

   $j('.aa').anchorAnimate();

}


jQuery.fn.anchorAnimate = function(settings) {
    return this.each(function() {
        var caller = this;
        jQuery(caller).click(function(event) {
            event.preventDefault()
            jQuery(caller).parent().addClass('active');
            var locationHref = window.location.href;
            var elementClick = jQuery(caller).attr("href");
            //elementClick = 'https:' + elementClick;
            if (elementClick === '#' || elementClick === 'https:#' || elementClick === '') {
                return;
            }
            var destination = jQuery(elementClick).offset().top || 0;
            jQuery("html:not(:animated),body:not(:animated)").animate({
                scrollTop: destination
            }, 1200, 'easeInOutExpo', function() {
                window.location.hash = elementClick;
            });
            return false;
        })
    })
}
