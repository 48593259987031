// Look into https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/import
(function ($, root, undefined) {

    $(function () {

        $('html').removeClass('no-js');

        mobile();
        nav();
        tabNav();
        //lightboxes();
        share();
        loader();
        home();
        swipers();
        consult();

        if ( !! $('.fullbio').length ) {
            $('.btnfullbio').click(function(){
                $('.fullbio').stop(true,false).slideToggle(500, 'easeInOutExpo');
            });
        }

    });

})(jQuery, this);
