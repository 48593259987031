
function lightboxes() {

    // Change defaults
    Fancybox.defaults.infinite = 0;
    Fancybox.defaults.animationEffect = "fade";
    Fancybox.defaults.transitionEffect = "fade";
    Fancybox.defaults.transitionDuration = "400";

    Fancybox.bind('[data-fancybox]', {
        buttons: ["zoom", "slideShow", "download", "thumbs", "close"],
    });

    Fancybox.bind('[data-type="iframe"]', {
        clickOutside : 'close',
        clickSlide: "close",
        media : {
            youtube : {
                params : {
                    autoplay : 1,
                    fs : 1,
                    iv_load_policy : 3,
                    rel : 0,
                    showinfo : 0,
                    showsearch : 0,
                    modestbranding : 1,
                    color : 'white'
                }
            }
        }
    });

    Fancybox.bind('[data-fancybox="gallery"]', {
        animationEffect: "zoom",
        thumbs: {
            autoStart : false
        },
        buttons: ["zoom", "slideShow", "download", "thumbs", "close"],
        autoplay: false,
        infobar: true,
        slideShow: {
            autoStart: false,
            speed: 3600
        }
    });

}
