var

$j = jQuery.noConflict(),

resizerfn = null,
scrollerfn = null,

_rps = 100, // resizes per second
_sps = 100, // scrolls per second
scrolledtop = 100, // top offset to determine scroll

loader_delay = 700,
loader_fadeout = 300,

msizer = $j('#msizer'), // javascript breakpoints at css values
body = $j('body'),


$default; // don't forget ';'
