function share() {

    if ( !! $j('.share').length ) {

        $j(document).on('click','.share', function(){
            $j(this).toggleClass('hover');
        })
    }

}
