function resizer() {
    var width = $j(document).width();
    var height = $j(document).height();

    // Manage classes on body for JS responsive,
    $j('body').removeClass('sm md lg xl xxl');

    log(width);

    if (msizer.width() > 40) {
        body.addClass('xxl');
    } else if (msizer.width() > 30) {
        body.addClass('xl');
    } else if (msizer.width() > 20) {
        body.addClass('lg');
    } else if (msizer.width() > 10) {
        body.addClass('md');
    } else {
        body.addClass('sm');
    }
}

$j(window).on('resize', function() {

    clearTimeout(resizerfn);
    resizerfn = setTimeout(resizer, _rps);

});
