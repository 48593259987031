function scroller() {

    var scrolltop = $j(window).scrollTop();

    if (scrolltop > scrolledtop) {
        $j('body').addClass('scrolled');
        $j('.scrolltop:hidden').stop(true, true).fadeIn();
    } else {
        $j('body').removeClass('scrolled');
        $j('.scrolltop').stop(true, true).fadeOut();
    }

}

$j(window).on('scroll', function() {

    clearTimeout(scrollerfn);
    scrollerfn = setTimeout(scroller, _sps);

});
