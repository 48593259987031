function home() {

    if ( !! $j('#home-banner').length ) {

        setTimeout(function(){

            var swiperNav = new Swiper('#swiper-hero-nav .swiper-container', {
                //effect: 'fade', // coverflow cube
                //freeMode: true,
                loop: true,
                //parallax: true,
                spaceBetween: 0,
                slidesPerView: 'auto',
                slideToClickedSlide: true,
                centeredSlides: true,
                setTransition: 5000,
                touchRatio: .1,
                longSwipesRatio: .1
            });

            var swiperHero = new Swiper('#swiper-hero .swiper-container', {
                //effect: 'fade', // coverflow cube
                //freeMode: true,
                loop: true,
                //parallax: true,
                autoplay: false,
                autoplay: {
                    delay: 6000
                },
                grabCursor: true,
                centeredSlides: true,
                setTransition: 5000,
                slidesPerView: 'auto',
                longSwipesRatio: .1,
                //watchSlidesVisibility: true,
                //watchSlidesProgress: true,
                //mousewheel: true,
                //direction: 'vertical',
                pagination: {
                    el: '#swiper-hero .swiper-pagination',
                    //type: 'progressbar',
                    //dynamicBullets: true,
                    clickable: true,
                    renderBullet: function (index, className) {
                        index = index + 1;
                        var name = index;
                        if(index<10) {
                            //name = '0'+index;
                        }
                        return '<span class="' + className + '">' + name + '</span>';
                    },
                },
                navigation: {
                    nextEl: '#swiper-hero .slide-bg',
                    prevEl: '#swiper-hero .swiper-prev',
                },
                keyboard: {
                    enabled: true,
                }
            });

            swiperHero.controller.control = swiperNav;
            swiperNav.controller.control = swiperHero;

            $j('#swiper-hero-nav').fadeTo(300,1);

            $j('.full-nav').find('.loader>.loader').each(function(){
                var el = $j(this);

                setTimeout(function(){
                    //el.fadeTo(300,1);
                }, getRandomInt('100', '600'));

            });

        },500);

        setTimeout(function(){
            $j('.swiper-container>div.loader .animation').fadeIn(300);
        },1000);

        setTimeout(function(){

            //$j('.swiper-container>div.loader').find('.preload').removeClass('preload');
            //$j('.swiper-container>div.loader .animation').fadeOut(300);

            $j('.full-nav').find('.loader').each(function(){
                var el = $j(this);

                setTimeout(function(){
                    el.fadeOut(300);
                }, getRandomInt('100', '600'));

            });

            setTimeout(function(){

                $j('.full-nav').find('.loader').each(function(){
                    var el = $j(this);
                    el.remove();
                });

            },800);

        },1100);

        setTimeout(function(){
            $j('.swiper-container>div.loader').fadeOut(700);
            //$j('.swiper-container img.intrologo').fadeOut(600);

        },1900);

    }

}
