function tabNav() {

    $j('.tab-nav li a').click(function(event) {

        event.preventDefault();

        var el = $j(this);
        var tabs = el.data('tabs');

        $j('.tab').hide();
        $j('.tab-nav').find('a.active').removeClass('active');
        el.addClass('active');

        var activeTab = el.attr('href');

        //window.location.hash = activeTab;
        $j(activeTab).fadeIn(300);

        return false;

    });

    setTimeout(function(){
        $j('.tab-nav li a.active').click();
    }, 0);

}

