function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

function open_window(url, width, height) {
    var thewindow;
    // screen.width means Desktop Width
    // screen.height means Desktop Height
    var center_left = (screen.width / 2) - (width / 2);
    var center_top = (screen.height / 2) - (height / 2);
    thewindow = window.open(url, "Title", "scrollbars=1, width=" + width + ", height=" + height + ", left=" + center_left + ", top=" + center_top);
    thewindow.focus();
}

function scrollToTop($) {
    $j("html:not(:animated), body:not(:animated)").animate({ scrollTop: 0 }, { duration: 1200, easing: 'easeInOutExpo' });
}

function titleCase(str) {
    str =
    str.split('-')
    .map(function(i) {
        return i[0].toUpperCase() + i.substr(1).toLowerCase();
    })
    .join(' ');

    return str;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function convertToSlug(Text) {
    return Text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-');
}

// var qstring = getUrlVars()['qstring'];
function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi,
        function(m, key, value) {
            vars[key] = value;
        });
    return vars;
}

function formatPhoneNumber(s) {
    var s2 = ("" + s).replace(/\D/g, '');
    var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
}
